'use client'

import { forwardRef, useEffect, VideoHTMLAttributes, useRef, useMemo } from 'react'
import { srcToImgix } from 'components/basic/Img'
import useIsMobile from 'hooks/useIsMobile'
import isChromatic from 'chromatic/isChromatic'

interface SourceProps {
	src: string
	type: string
}

interface VideoGifProps extends VideoHTMLAttributes<HTMLVideoElement> {
	sources?: SourceProps[]
	mobileSrc?: string
}

const processVideoPoster = (videoProps: VideoGifProps) => {
	if (!videoProps) return undefined
	if (!videoProps.poster) return undefined

	return srcToImgix(videoProps.poster)
}

export const VideoGif = forwardRef<HTMLVideoElement, VideoGifProps>((props, ref) => {
	const { sources, ...videoProps } = props
	const videoRef = useRef<HTMLVideoElement>(null)

	const combinedRef = useMemo(() => {
		return (node: HTMLVideoElement) => {
			videoRef.current = node
			if (typeof ref === 'function') {
				ref(node)
			} else if (ref) {
				ref.current = node
			}
		}
	}, [ref])

	const poster = processVideoPoster(videoProps)
	const isMobile = useIsMobile(1024)
	const getSrc = () => {
		if (!videoProps.mobileSrc) return videoProps.src
		if (isMobile) return videoProps.mobileSrc
		return videoProps.src
	}

	const src = getSrc()

	const videoPropsWithoutGifProps = { ...props }
	delete videoPropsWithoutGifProps.sources
	delete videoPropsWithoutGifProps.mobileSrc

	useEffect(() => {
		const video = videoRef.current
		if (!video) return () => {}

		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					// Disable playing in chromatic testing to avoid flaky UI tests
					if (entry.isIntersecting && !isChromatic()) {
						video.play().catch((error) => {
							console.warn('AutoPlay failed:', error)
						})
					}
				})
			},
			{
				root: null,
				threshold: 0.1, // 10% of the element is visible
			}
		)

		observer.observe(video)

		return () => {
			observer.disconnect()
		}
	}, [src])

	return (
		<video
			playsInline
			disablePictureInPicture
			disableRemotePlayback
			preload="metadata"
			loop
			muted
			ref={combinedRef}
			{...videoPropsWithoutGifProps}
			src={src}
			poster={poster}
			key={src}
		>
			{sources?.map((source, index) => (
				<source
					key={index}
					src={source.src}
					type={source.type}
				/>
			))}
		</video>
	)
})

VideoGif.displayName = 'VideoGif'
